<template>
  <router-link class="portfolio-card" :to="url">
    <div class="portfolio-card__overlay" :class="{visible:hasOverlay}">
      <span>{{ title }}</span>
    </div>
    <div class="portfolio-card__background" :style="{ backgroundImage: 'url(' + require('@/assets/'+imgUrl) + ')' }"/>
  </router-link>
</template>

<script>
export default {
  name: "PortfolioCard",
  props: {
    title: {
      type: String,
      default: "Titolo"
    },
    imgUrl: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: '',
    },
    hasOverlay: {
      type: Boolean,
      default: false
    }
  },

}
</script>

<style lang="scss" scoped>
.portfolio-card {
  @apply relative flex w-full;
  height: 24rem;
  clip: rect(20vh, 85vw, 80vh, 15vw);
  transform: translate3D(0, 0, 0);
  -webkit-transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
  transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);

  @screen md {
    height: 32rem;
  }

  &__overlay {
    @apply inset-0 absolute z-10 flex;

    &.visible {
      background-color: rgba(0, 0, 0, 0.75);
    }

    span {
      @apply m-auto z-30 text-center text-4xl font-bold;
      -webkit-transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
      transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);

      @screen md {
        @apply text-7xl;
      }
    }
  }


  &__background {
    @apply flex bg-white w-full h-full hover:shadow-xl bg-no-repeat bg-cover m-auto;
  }


  &:hover {
    @screen md {
      transform: translate3D(0, 0, 0) scale(1.1);
    }

    span {
      transform: translate3D(0, 0, 0) scale(1.1);
    }
  }
}
</style>