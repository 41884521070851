<template>
  <div>
    <h2 class="px-2 md:px-12 text-2xl font-medium uppercase">
      {{ title }}
    </h2>
    <div class="grid grid-cols-1 gap-8 md:gap-12 md:px-12 mt-12">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioCardsWrapper",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>