<template>
  <div>
    <section ref="backgroundContainer" class="background-container"
             :style="{ backgroundImage: 'url(' + require('@/assets/portfolio/portfolio-bg.webp') + ')' }"
    >
      <div class="background-container__overlay"></div>
    </section>
    <section class="flex h-screen items-center fixed w-full" ref="titleContainer" style="top:250px;">
      <h1 ref="mainTitle">Portfolio</h1>
    </section>
    <section class="scrollable-container">
      <div class="scrollable-container__inner">
        <!-- Native applications -->
        <PortfolioCardsWrapper title="native applications">
          <PortfolioCard img-url="portfolio/bitelife/bitelife.webp" has-overlay url="/portfolio/bitelife"
                         title="Bitelife"/>
        </PortfolioCardsWrapper>
        <!-- Web App -->
        <PortfolioCardsWrapper title="web app">
          <PortfolioCard img-url="portfolio/bitelife-admin/bitelife-admin.webp" has-overlay
                         url="/portfolio/bitelife-admin"
                         title="Bitelife Admin"/>
        </PortfolioCardsWrapper>
        <!-- UI Design -->
        <PortfolioCardsWrapper title="UI Design">
          <PortfolioCard img-url="portfolio/meritocracy/meritocracy.webp" url="/portfolio/meritocracy"
                         title="Meritocracy"/>
          <PortfolioCard img-url="portfolio/timerly/timerly.webp" has-overlay url="/portfolio/timerly"
                         title="Timerly"/>
        </PortfolioCardsWrapper>
      </div>
    </section>
  </div>
</template>

<script>
import {ref, onMounted, onUnmounted} from 'vue'
import PortfolioCard from "@/modules/portfolio/components/PortfolioCard";
import PortfolioCardsWrapper from "@/modules/portfolio/components/PortfolioCardsWrapper";

export default {
  name: "portfolio",
  components: {PortfolioCardsWrapper, PortfolioCard},
  setup() {

    const backgroundContainer = ref(null)
    const titleContainer = ref(null)
    const mainTitle = ref(null)

    onMounted(() => {
      handleScroll();
      document.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      document.removeEventListener('scroll', handleScroll)
    })

    /**
     * @desc Handle scroll function
     */
    const handleScroll = () => {

      const scrollY = window.scrollY

      //console.log(scrollY)
      if (scrollY > 250) {
        titleContainer.value.style.top = '250px';
        backgroundContainer.value.classList.add('has-overlay');
        mainTitle.value.classList.add('plain');
        titleContainer.value.style.transform = 'translateY(-' + scrollY + 'px)'
      } else {
        backgroundContainer.value.classList.remove('has-overlay');
        mainTitle.value.classList.remove('plain');
        titleContainer.value.style.transform = 'translateY(-250px)'
      }
    }
    return {
      backgroundContainer,
      titleContainer,
      mainTitle
    }
  }

}
</script>

<style lang="scss" scoped>
h1 {
  @apply text-5xl m-auto font-bold text-red-700 p-2 px-6 m-0 rounded-3xl pt-4 transition-all transition ease-in-out duration-200;
  background-color: rgba(1, 12, 23, 0.95);

  &.plain {
    background-color: transparent;
  }

  @screen md {
    @apply text-7xl;
  }
}

.background-container {
  @apply flex h-screen fixed w-full bg-center bg-no-repeat opacity-75 leading-3;
  background-size: 35vh;
  will-change: border;
  border-width: 0;
  transition: border 300ms ease-in-out;
  border-color: #fff;

  @screen sm {
    background-size: 50vh;
  }

  @screen md {
    background-size: 70vh;
  }

  @screen lg {
    background-size: 90vh;
  }

  &__overlay {
    will-change: transform;
    @apply fixed inset-0 w-full h-full bg-black opacity-0 z-10 transition transition-all duration-500 ease-in-out;
  }

  &.has-overlay {
    border-width: 1rem;
    @screen sm {
      border-width: 2rem;
    }

    .background-container__overlay {
      @apply opacity-90;
    }
  }

}

.scrollable-container {
  @apply relative z-50 w-full flex pb-20;
  top: 90vh;

  &__inner {
    @apply grid grid-cols-1 w-full gap-20 max-w-screen-xl m-auto;
  }
}
</style>